







































































































































import Register from '@/components/Component/Core/Register.vue';

import {
    Component,
    Vue,
} from 'vue-property-decorator';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { App } from "@/store/app";
const sign_in_btn = document.querySelector("#sign-in-btn");
const sign_up_btn = document.querySelector("#sign-up-btn");
const containerR = document.querySelector(".containerR");
@Component({
    components: {
        Register,
    },
    computed: {}
})

export default class Test extends Vue {
    api: any = process.env.VUE_APP_SERVER
    tab: number = 0
    switchc: string = ''
    dialogLogin:boolean = false
    private form: any = {
        "username": "",
        "password": ""
    }

    private async login() {
        await Auth.removeToken();
        let user: any = await Core.postHttp('/api/rest-auth/login/', this.form)
        if (user.key) {
            await Auth.storeToken(user.key)
            await Auth.storeTokenToStorage(user.key)
            await User.loadUser()
            await this.$router.replace(User.routeUser)
        } else {
            await App.error("เข้าสู่ระบบล้มเหลว กรุณาตรวจสอบข้อมูลให้ถูกต้อง")
        }
    }

    async created() {
        await Auth.checkToken();
        await User.loadUser();
        await this.$router.replace(User.routeUser)
        console.log(User.routeUser)

    }

    async goAdmin() {

        if (this.$device.android || this.$device.ios) {
            await this.$router.push('adminmobile')
        } else {
            window.location.href = 'https://www.buffalocert.agri.up.ac.th/admin/';
        }
    }

    async ChangeTo(val: string) {
        this.switchc = val
        await this.sleep(1000)
        this.tab = (val) ? 1 : 0
    }
    async sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
