

















































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Core } from "@/store/core";
import { City } from "@/store/city";
import { Auth } from "@/store/auth";
import { FormRegister, Profile, Province } from "@/models/core";
import CityDialog from "@/components/Dialog/City.vue";
import moment from "moment";
import { App } from "@/store/app";
import { User } from "@/store/user";
@Component({
  components: { CityDialog },
  computed: {},
})
export default class Home extends Vue {
  public step: number = 0;
  private gender: [] = [];
  private prefix: [] = [];
  public formUser: FormRegister | any = {};
  public formProfile: Profile | any = {};
  public location: any = "19.0290389,99.8906438";

  @Watch("formProfile.birthday")
  async onChangeProvince(val: string) {
    this.formProfile.age = moment().diff(val, "years", false);
  }
  async created() {
    await this.getLocation();
    this.gender = await Core.getChoice("เพศ");
    this.prefix = await Core.getChoice("คำนำหน้า");
  }

  async getLocation() {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((location: any) => {
        this.location = `${location.coords.latitude},${location.coords.longitude}`;
      });
    }
  }

  async register() {
    this.formProfile.geo = City.currentGeo?.id;
    this.formProfile.province = City.currentProvince?.id;
    this.formProfile.amphur = City.currentAmphur?.id;
    this.formProfile.district = City.currentDistrict?.id;
    this.formProfile.birthday = this.formProfile.birthday
      ? this.formProfile.birthday
      : "2021-06-06";
    this.formProfile.age = this.formProfile.age ? this.formProfile.age : 25;
    let user = await Auth.register(
      this.formUser,
      this.formProfile,
      this.location
    );
    if (user.id) {
      await App.success("สมัครสมาชิกสำเร็จ");
      await this.login();
      await this.$router.go(-1);
    } else {
      let errorText = JSON.stringify(user["username"])
        ? JSON.stringify(user["username"][0])
        : JSON.stringify(user.password);
      await App.error("ไม่สามารถสมัครสมาชิกได้ " + errorText);
    }
  }

  private async login() {
    await Auth.removeToken();
    let user: any = await Core.postHttp("/api/rest-auth/login/", this.formUser);
    if (user?.key) {
      await Auth.storeToken(user.key);
      await Auth.storeTokenToStorage(user.key);
      await User.loadUser();
      await this.$router.replace(User.routeUser);
    } else {
      await App.error("เข้าสู่ระบบล้มเหลว กรุณาตรวจสอบข้อมูลให้ถูกต้อง");
    }
  }

  async openCityDialog() {
    City.dialogCityState = true;
  }

  get CityFrom() {
    return City.showName;
  }
  get CityDialogLayout() {
    return City.dialogCityState;
  }

  get headerClass() {
    return this.step == 0 ? "text-xs" : "text-xl";
  }

  get header() {
    switch (this.step) {
      case 0:
        return "สร้างบัญด้วยขั้นตอนง่ายๆ เพียงไม่กี่ขั้นตอน";
      case 1:
        return "โปรดระบุชื่อของคุณ";
      case 2:
        return "วันเกิดของคุณคือวันที่เท่าไร";
      case 3:
        return "โปรดระบุข้อมูลส่วนตัว";
      case 4:
        return "ช่องทางการติดต่อของคุณ";
      case 5:
        return "โปรดระบุข้อมูลที่จะเข้าสู่ระบบ";
      default:
        console.log("No such day exists!");
        break;
    }
  }

  btn: string =
    "text-white  text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";
  input: string =
    "px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150";
}
